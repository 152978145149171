import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import "../components/EmailTagsInput.css";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addEmailToList, removeEmailFromList, setEmailValidationError, setClearEmailValidation } from '../store/reducers/emailFilesReducer';

const EmailTagsInput = () => {
    const { t } = useTranslation();
    const refEmailTextBox = useRef(null);
    const [input, setInput] = useState('');
    const [emailError, setEmailError] = useState('');
    const dispatch = useDispatch();
    const emails = useSelector((state) => state.emailFiles.emailsList);

    const handleInputChange = (e) => {
        setInput(e.target.value.trim());
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',' || e.key === ';') {
            e.preventDefault();
            e.stopPropagation();
            validateAddEmail();
        }
    };

    const handleEmailPaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('Text');
        const emailsArray = pastedText.split(/[\s,;]+/)
            .map(email => email.trim()).filter(email => email.length > 0);
        validateAddMultipleEmails(emailsArray);
    };

    const validateAddEmail = () => {
        //const input = refEmailTextBox.current.value;
        if (validateEmail(input)) {
            setEmailError('');
            dispatch(addEmailToList(input));
            setInput('');
        } else {
            if (input.trim().length > 0) {
                setEmailError(t('validemailaddress'));
                dispatch(setEmailValidationError());
            }
            else if (emails.length === 0) {
                setEmailError(t('oneemailaddress'));
                dispatch(setEmailValidationError());
            }
            else {
                setEmailError('');
                dispatch(setClearEmailValidation());
            }
        }
    };

    const validateAddMultipleEmails = (emailsArray) => {
        let validEmails = [];
        let invalidEmails = [];
        emailsArray.forEach(email => {
            if (validateEmail(email)) {
                validEmails.push(email.toLowerCase());
            }
            else { 
                invalidEmails.push(email); 
            }
        });

        if (validEmails.length > 0) {
            validEmails.forEach(email => {
                dispatch(addEmailToList(email));
            });
        }

        if (invalidEmails.length > 0) {
            setInput(invalidEmails.join(', '));
            setEmailError(t('oneemailaddress'));
            dispatch(setEmailValidationError());
        }
        else {
            setEmailError('');
            setInput('');
            dispatch(setClearEmailValidation());
        }
    };

    const removeEmail = (index) => {
        dispatch(removeEmailFromList(emails[index]));
    };

    useEffect(() => {
        const setFocusTimer = setTimeout(() => {
            if (refEmailTextBox.current) {
                refEmailTextBox.current.focus();
            }
        }, 500);

        return () => clearTimeout(setFocusTimer);
    }, []);

    useEffect(() => {
        if (emails.length === 0) {
            setEmailError(t('oneemailaddress'));
            dispatch(setEmailValidationError());
        }
    }, [emails]);

    return (
        <>
            <div className="email-tags">
                {emails.map((email, index) => (
                    <div key={index} className="email-tag">
                        {email}
                        <AiOutlineClose onClick={() => removeEmail(index)} />
                    </div>
                ))}
                <input
                    type="text"
                    ref={refEmailTextBox}
                    onBlur={validateAddEmail}
                    value={input}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    onPaste={handleEmailPaste}
                    className="email-tag-input"
                    placeholder={t("Email")}
                />
            </div>
            <p className="email-error-message error-message">{emailError}</p>
        </>
    );
};

export default EmailTagsInput;