import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div style={{ marginLeft: "-60px" }} >
      <span>
        <button className="button-style"
          onClick={() => changeLanguage('en')}>English</button>
        <button className="button-style"
          onClick={() => changeLanguage('sp')}>Español</button>
      </span>
    </div>
  );
};

export default LanguageSwitcher;
