import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import "../newComponent/FolderModal.css";
import { use } from "i18next";

function CreateFolderModal({ isOpen, onClose, onSubmit, currentFolderPath }) {
  const { t } = useTranslation();
  const [folderName, setFolderName] = useState("");
  const [folderErrMsg, setFolderErrMsg] = useState("");

  const inputRef = useRef(null); // Reference for the input element

  // Focus the input field when the modal opens
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);
  useEffect(() => {
    if(folderName.length > 0)
    setFolderErrMsg("");
  
  }, [folderName]);

  const handleSubmit = () => {
    if (folderName.trim() === "") {
      setFolderErrMsg(t("EmptyFolderName"));
      return false;
    }
    else {
      setFolderErrMsg("");
    }
    onSubmit(folderName);
    setFolderName("");
    onClose();
  };

  const handelKeyDown = (event) => {
    if (event.key === "Enter") {
      if (isOpen) {
        event.preventDefault();
        event.stopPropagation();
        handleSubmit();
      }
    } else if (event.key === "Escape") {
      onClose();
    }
  };

  if (!isOpen) return null;

  const handelChange = (e) => {
    const inputValue = e.target.value;

    if (!inputValue.includes("/")) {
      setFolderName(inputValue);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>{t('FolderName')}</h3>
        <input
          ref={inputRef}
          type="text"
          placeholder={t("EnterFolderName")}
          value={folderName}
          // onChange={(e) => setFolderName(e.target.value)}
          onChange={handelChange}
          onKeyDown={handelKeyDown}
        />
        {folderErrMsg && (
          <div className="error-msg">
          {folderErrMsg}
          </div>)}

        <div className="modal-buttons">
          <button className="cancel-button" onClick={onClose}>
            {t('Cancel')}
          </button>
          <button className="ok-button" onClick={handleSubmit}>
            {t('OK')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateFolderModal;
