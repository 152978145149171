// import UploadScreen from "../screens/UploadScreen";
import "../components/UploadOverlay.css";
import { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";

import CloseIcon from "@mui/icons-material/Close";
import { auth, db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";

import ErrorPopup from "../newComponent/ErrorPopUp";
import { message } from "antd";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import Axios from "axios";
import { useUserDetails } from "../customHook/userDetails";
import Feedback from "../newComponent/Feedback";

import { allowedExtensions } from "./APP_LOGO/allowedExtensions";
import { useDispatch } from "react-redux";
import { setSearchTerm } from "../store/reducers/searchReducer";

import { useTranslation } from 'react-i18next';

export const UPLOAD_API_URL = process.env.REACT_APP_UPLOAD_API_URL;

export default function UploadOverlay({
  onClose,
  isOpen,
  folderPath,
  onUploadComplete,
  handleFileOperationComplete,
  handleRefreshAfterUpload,
  isUploadForFeedback,

  setSelectedFolder,
  setIsFavoritesResults,
  setIsTrashResults,
  setIsRecentResults,
  selectedFolder,
  isSearchResults,
  setIsSearchResults,
  setCurrentFolderPath,
}) {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingName, setEditingName] = useState("");
  const [fileError, setFileError] = useState("");
  const [error, setError] = useState(null);
  const [filesLoading, setFileLoading] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false); // State for showing the error popup

  const [messageApi, messageContextHolder] = message.useMessage();

  const { userDetail, loading, accessToken } = useUserDetails();
  // const { userDetail, loading, refreshAccessToken } = useUserDetails();
  const [triggerFeedbackSubmit, setTriggerFeedbackSubmit] = useState(false);
  isUploadForFeedback === undefined
    ? (isUploadForFeedback = false)
    : (isUploadForFeedback = isUploadForFeedback);
  const title = isUploadForFeedback ? "Feedback" : "Upload";

  console.log(
    `PRINTING THE CURRENT FOLDER PATH WHILE ENTERING UPLOAD : |${folderPath}|`
  );

  if (
    selectedFolder === "__Favorites__" ||
    selectedFolder === "__Recent__" ||
    selectedFolder === "__Trash__"
  ) {
    setCurrentFolderPath("");
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setFiles([]);
      setError(null);
      setFileLoading(false);
      setShowErrorPopup(false); // Ensure popup is closed when opening overlay
    }

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  if (loading) {
    return (
      <div className="spinner-container">
        <RingLoader color="#3498db" />
      </div>
    );
  }

  if (!userDetail) {
    return <div>No user details available</div>;
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    addFiles(newFiles);
  };

  const handleFileSelect = (e) => {
    const newFiles = Array.from(e.target.files);
    addFiles(newFiles);
  };

  const addFiles = (newFiles) => {
    const totalFiles = files.length + newFiles.length;

    if (totalFiles > 4) {
      setFileError(t("Select4FilesMsg"));
      return false;
    }
    else {
      setFileError("");
    }
    const updatedFiles = [
      ...files,
      ...newFiles.map((file) => ({
        file,
        name: file.name,
        size: file.size,
        category: "",
        path: folderPath,
      })),
    ];
    setFiles(updatedFiles);
    console.log(updatedFiles);
  };

  const handleDelete = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const handleEditFileName = (index, newName) => {
    const updatedFiles = [...files];
    const fileExtension = getFileExtension(updatedFiles[index].name);
    updatedFiles[index].name = `${newName}.${fileExtension}`;
    setFiles(updatedFiles);
    setEditingIndex(null); // Exit edit mode
  };

  const preventDefault = (e) => e.preventDefault();

  const getFileNameWithoutExtension = (fileName) => {
    return fileName.split(".").slice(0, -1).join(".");
  };

  const getFileExtension = (fileName) => {
    return fileName.split(".").pop();
  };

  console.log(
    "Current Folder Path before submission In UploadPage:",
    folderPath
  );

  const handleRefresh = () => {
    handleRefreshAfterUpload();
  };

  const handleSubmit = async () => {
    if (isUploadForFeedback) {
      await handleFeedbackSubmit();
    } else {
      setFileLoading(true);
      await handleUploadSubmit();
    }
  };

  const handleFeedbackSubmit = async () => {
    setTriggerFeedbackSubmit(true);
  };

  const handleFeedbackSubmitComplete = async (response) => {
    setFileLoading(false);
    setTriggerFeedbackSubmit(false);
    if (response.result) {
      messageApi.open({
        type: "success",
        content: t("Feedbacksentsuccessfully"),
        duration: 3,
      });
      setTimeout(onClose, 1000);
      setFiles([]);
    } else {
      if (response.errorType === "Validation") {
        messageApi.open({
          type: "error",
          content: response.status,
          duration: 3,
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Failed to send feedback! Please try again.",
          duration: 3,
        });
      }
    }
  };

  const handleUploadSubmit = async () => {
    setFileLoading(true); // Start the loading state
    setError(null); // Clear any previous errors

    const formData = new FormData();

    let filesList = [];

    console.log(files);

    files.forEach((fileWrapper) => {
      let myCheck = "";

      if (folderPath !== null) {
        myCheck = folderPath;
      }

      const uploadInfo = {
        files: fileWrapper.file,
        name: fileWrapper.name,
        category: "3300",
        userid: userDetail.email,
        uploadPath: myCheck,
      };
      filesList.push(uploadInfo);

      console.log(fileWrapper); // Log to check fileWrapper contents
      formData.append("files", fileWrapper.file);
      formData.append("category", fileWrapper.category || "3300");
      formData.append("name", fileWrapper.name || "defaultName");
      formData.append("userid", userDetail.email || "defaultUser");

      console.log(`MY CHECK XXX :  |${myCheck}|`);
      formData.append("uploadPath", myCheck);

      // formData.append("path", myCheck);
    });
    formData.append("uploadPath", "");
    console.log(`ALL FILES LIST : ${JSON.stringify(filesList)}`);
    console.log(`ALL FILES LIST : ${JSON.stringify()}`);

    // Debugging: Log FormData to see what is being sent
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    console.log("--------------------------------");
    console.log(formData);

    try {
      const headers = {
        "Cache-Control": "no-cache",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };
      console.log("Request Headers In UPLOAD:", headers); // Log the headers
      console.log("AccessToken In UPLOAD:", accessToken); // Log the headers

      const response = await Axios.post(
        `${UPLOAD_API_URL}/multi/details`,
        formData,
        {
          headers: headers,
        }
      );

      // console.log("Response data:", response.data);

      if (response.status === 200) {
        setFiles([]); // Clear files on successful upload
        setError(null); // Clear error state
        onClose();
        // handleRefresh();
        // onUploadComplete(folderPath);

        if (
          selectedFolder === "__Favorites__" ||
          selectedFolder === "__Recent__" ||
          selectedFolder === "__Trash__" ||
          isSearchResults === true
        ) {
          setSelectedFolder("Home");
          setCurrentFolderPath("");
          setIsFavoritesResults(false);
          setIsRecentResults(false);
          setIsTrashResults(false);
          setIsSearchResults(false);
          dispatch(setSearchTerm(""));
          localStorage.setItem("folderPath", "Home");
        }

        console.log(`AFTER CALLING FUNCTION: ${folderPath}`);
        handleRefresh();
      } else {
        console.error("Upload failed:", response.data);
        setError("Upload failed: " + response.data.message); // Set error message
        setShowErrorPopup(true); // Show error popup
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setError("Error uploading files."); // Display upload error
      setShowErrorPopup(true); // Show error popup
    } finally {
      setFileLoading(false); // End the loading state
    }
  };

  const formatFileSize = (size) => {
    if (size < 1024) return size + " bytes";
    else if (size < 1048576) return (size / 1024).toFixed(2) + " KB";
    else return (size / 1048576).toFixed(2) + " MB";
  };

  // Return null if the overlay is not open
  if (!isOpen) return null;

  console.log(files);

  // (jpg|jpeg|png|tiff|gif|bmp|iiq|3fr|dcr|k25|kdc|cr2|nef|crw|erf|mef|mos|nef|nrw|orf|pef|rw2|arw|srf|sr2|heif|mp3|mp4|hevc|doc|docx|xls|xlsx|csv|ppt|pptx|txt|pdf)$

  //  Collect In-valid extension
  const invalidExtensionsSet = new Set();

  files.forEach((file) => {
    const parts = file.name.split(".");
    const extension = parts.length > 1 ? parts.pop().toLowerCase() : file.name;
    if (!allowedExtensions.includes(extension)) {
      invalidExtensionsSet.add(extension);
    }
  });

  // Convert the Set to an array for use
  const invalidExtensions = Array.from(invalidExtensionsSet);

  // Generate error message
  const errorMessage =
    invalidExtensions.length > 0
      ? `${invalidExtensions.join(", ").toUpperCase()} ${t("IsnotSupported")}`
      : "";

  return (
    <>
      {messageContextHolder}
      {showErrorPopup && (
        <ErrorPopup
          message={error}
          onClose={() => {
            setShowErrorPopup(false);
            setError(null); // Reset error state when closing
          }}
        />
      )}
      {isUploadForFeedback && (
        <div className="feedback-section">
          <Feedback
            accessToken={accessToken}
            uploadedFiles={files}
            triggerFeedbackSubmit={triggerFeedbackSubmit}
            handleFeedbackSubmitComplete={handleFeedbackSubmitComplete}
            setTriggerFeedbackSubmit={setTriggerFeedbackSubmit}
          />
        </div>
      )}
      <div className="upload-section">
        <div
          className={
            isUploadForFeedback
              ? "drop-zone feedback-drop-zone"
              : "drop-zone"
          }
          onDrop={handleDrop}
          onDragOver={preventDefault}
          onDragEnter={preventDefault}
        >
          <span>{t('Drag&Drop')}</span>
        </div>
        <div className="drop-zone-select">
          <button
            className={
              isUploadForFeedback
                ? "button select-files-button feedback-button-size"
                : "button select-files-button"
            }
            onClick={() => document.getElementById("file-input").click()}
          >
            {t('Select Files')}
          </button>
        </div>
      </div>

      {filesLoading && (
        <div className="loader-container">
          <RingLoader color="#3498db" />
        </div>
      )}

      <input
        id="file-input"
        type="file"
        multiple
        onChange={handleFileSelect}
        style={{ display: "none" }}
      />

      <div style={{ textAlign: "center", marginTop: "15px" }}>
        {fileError && (
          <p className="email-error-message error-message">
            {fileError}
          </p>
        )}
      </div>
      {files.length > 0 ? (
        <div
          className={
            isUploadForFeedback
              ? "tab-container feedback-tab-container"
              : "tab-container"
          }
        >
          <div className="file-table custom-scrollbar" style={{ width: "98%" }}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>{t('Delete')}</th>
                  <th style={{ width: "70%" }}>{t('FileName')}</th>
                  <th style={{ width: "20%" }}>{t('FileSize')}</th>
                </tr>
              </thead>

              <tbody>
                {files.map((fileWrapper, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        type="submit"
                        className="delete-button"
                        onClick={() => handleDelete(index)}
                      >
                        X
                      </button>
                    </td>
                    <td>
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={editingName}
                          onChange={(e) => setEditingName(e.target.value)}
                          onBlur={() =>
                            handleEditFileName(index, editingName)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleEditFileName(index, editingName);
                            }
                          }}
                          autoFocus
                        />
                      ) : (
                        <div
                          className="file-name-wrapper"
                          onClick={() => {
                            setEditingIndex(
                              isUploadForFeedback ? null : index
                            );
                            setEditingName(
                              getFileNameWithoutExtension(fileWrapper.name)
                            );
                          }}
                        >
                          <span className="file-name">
                            {fileWrapper.name}
                          </span>
                          {!isUploadForFeedback && (
                            <EditOutlinedIcon
                              style={{ width: "16px", fill: "#008631" }}
                              className="edit-icon"
                            />
                          )}
                        </div>
                      )}
                    </td>
                    <td style={{ textAlign: "left", fontSize: "11px" }}>
                      {formatFileSize(fileWrapper.size)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        !isUploadForFeedback && (
          <div className="message">{t('Nofilesselected')}.</div>
        )
      )}
      {errorMessage && (
        <div
          style={{
            textAlign: "center",
            backgroundColor: "white",
            color: " #ff5c5c",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "13px",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {errorMessage}
        </div>
      )}
      <div
        className={
          isUploadForFeedback
            ? "feedback-submit-button-container"
            : "submit-button-container"
        }
      >
        {(isUploadForFeedback || files.length > 0) &&
          files.length <= 4 &&
          !errorMessage ? (
          <button
            className={
              isUploadForFeedback
                ? "submit-button feedback-button-size"
                : "submit-button"
            }
            disabled={filesLoading} // Disable only during file upload
            onClick={handleSubmit}
          >
            {t('Submit')}
          </button>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

