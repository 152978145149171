import { useEffect, useState, useRef } from "react";
import FeedbackService from "../services/feedbackService";
import "../newComponent/Feedback.css";
import { useTranslation } from 'react-i18next';

export default function Feedback({ accessToken, uploadedFiles, triggerFeedbackSubmit, handleFeedbackSubmitComplete, setTriggerFeedbackSubmit }) {
  const { t } = useTranslation();
  const [txtFeedback, setTxtFeedback] = useState("");
  const [feedbackErrMsg, setFeedbackErrMsg] = useState("");
  const feedbackRef = useRef(null);
  let _feedbackResult = {
    data: [],
    result: false,
    status: "",
    errorType: "Validation" | "Server" | "Unknown"
  };

  useEffect(() => {
    if (triggerFeedbackSubmit) {
      if(validateFeedbackBody() === true) {
        console.log("Feedback Result:", "trigerred");
        handleFeedbackSubmit();
      }
      setTriggerFeedbackSubmit(false);
    }
  }, [triggerFeedbackSubmit]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent the default behavior for up and down arrow keys
      if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    // Add event listener to document when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Focus the textarea
    const textarea = feedbackRef.current;
    if (textarea) {
      textarea.focus();
    }

    // Cleanup event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // useEffect(() => {
  //   if (txtFeedback.length >= 10) {
  //     setFeedbackErrMsg("");
  //   }
  // }, [txtFeedback]);

  const validateFeedbackBody = () => {
    if (txtFeedback.trim().length < 10 || txtFeedback.trim().length > 500) {
      setFeedbackErrMsg(t("Pleaseprovidefeedback"));
      return false;
    } else {
      setFeedbackErrMsg("");
      return true;
    }
  };

  const handleFeedbackSubmit = async (e) => {
    debugger;
    if (validateFeedbackBody() === false) {
      //_feedbackResult.errorType = "Validation";
      //_feedbackResult.status = t("Pleaseprovidefeedback");
      setFeedbackErrMsg(t("Pleaseprovidefeedback"));
      console.log("Feedback Result:", triggerFeedbackSubmit);
      //handleFeedbackSubmitComplete(_feedbackResult);
      return false;
    }

    try {
      _feedbackResult = await FeedbackService(accessToken, txtFeedback, uploadedFiles);
      handleFeedbackSubmitComplete(_feedbackResult);
    } catch (err) {
      console.log("Error sending Feedback:", err);
      _feedbackResult.status = err;
      _feedbackResult.result = false;
      _feedbackResult.data = [];
      handleFeedbackSubmitComplete(_feedbackResult);
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <textarea
        placeholder={t("Feedback")}
        ref={feedbackRef}
        className="feedback-textarea"
        value={txtFeedback}
        maxLength={500}
        rows={3}
        onChange={(e) => setTxtFeedback(e.target.value)}
        onBlur={validateFeedbackBody}
      />
      <div style={{ backgroundColor: "white", marginTop: "0px" }}>
        {feedbackErrMsg &&
          <p style={{ marginLeft: "5%" }} className="email-error-message error-message">
            {feedbackErrMsg}
          </p>
        }</div>
    </div>
  );
}