import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setSearchTerm } from "../store/reducers/searchReducer";

import "../newComponent/SideBar.css";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import UploadOverlay from "../components/UploadOverlay";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RecentFiles from "./RecentFiles";
import Favorites from "./Favorites";
import Trash from "./Trash";
import { useTranslation } from 'react-i18next';

function getMaxCharactersFromWidth(sidebarWidth, level) {
  // Map the sidebar width to the number of characters
  // At 200px width, 5 characters, at 350px width, 10 characters
  const minWidth = 200;
  const maxWidth = 350;
  let minChars = 11;
  let maxChars = 30;

  if (level === 4) {
    minChars = 8;
    maxChars = 25;
  } else if (level === 3) {
    minChars = 8;
    maxChars = 23;
  } else if (level >= 5) {
    maxChars = 25;
    minChars = 5;
  }
  // else if (level > 7) {
  //   maxChars = 15;
  // }

  // Calculate the number of characters proportionally
  const characters = Math.round(
    minChars +
      ((sidebarWidth - minWidth) / (maxWidth - minWidth)) *
        (maxChars - minChars)
  );

  return characters;
}

function Folder({
  folderName,
  folderPath,
  children,
  onClickFolder,
  selectedFolder,
  level = 0, // Indentation level
  autoOpen = false, // New prop to control auto-opening
  sideBarWidth,
}) {
  const [isOpen, setIsOpen] = useState(autoOpen);
  const toggleFolder = () => {
    setIsOpen(!isOpen);
    if (onClickFolder) {
      onClickFolder(folderPath);
    }
  };

  // Determine if the folder name is long (more than 6 characters)
  const isLongName = folderName.length > 6;

  // Truncate the folder name if it's long
  const displayName = isLongName ? `${folderName.slice(0, 6)}...` : folderName;

  // Automatically open the folder if it's selected
  React.useEffect(() => {
    if (selectedFolder && selectedFolder.startsWith(folderPath)) {
      setIsOpen(true);
    }
  }, [selectedFolder]);

  // -----------------------------------------------------------

  // Calculate the maximum number of characters based on sidebar width
  const maxChars = getMaxCharactersFromWidth(sideBarWidth, level);

  // Truncate the folder name based on the calculated number of characters
  const truncatedName =
    folderName.length > maxChars
      ? `${folderName.slice(0, maxChars)}...`
      : folderName;

  return (
    <div className="folder">
      <div
        className={`sidebar1-option folder-header ${
          selectedFolder === folderPath ? "selected" : ""
        }`}
        onClick={toggleFolder}
      >
        <span
          style={{
            paddingLeft: `${level * 7}px`,
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {isOpen ? <ArrowDropDownSharpIcon /> : <ArrowRightSharpIcon />}
          {isOpen ? <FolderIcon /> : <FolderIcon />}
          <span
            className={`folder-name ${isLongName ? "long-name" : ""}`}
            title={folderName}
          >
            {truncatedName}
          </span>
        </span>
      </div>

      {isOpen && children && (
        <div className="folder-children">
          {Object.keys(children).map((childFolder) => (
            <Folder
              key={childFolder}
              folderName={childFolder}
              folderPath={`${folderPath}/${childFolder}`}
              children={children[childFolder]}
              onClickFolder={onClickFolder}
              selectedFolder={selectedFolder}
              level={level + 1}
              sideBarWidth={sideBarWidth}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function SideBar1({
  folderTree,
  onFolderClick,
  selectedFolder,
  onUploadButtonClick,
  ContextCloseHandeler,
  onCreateFolderClick,
  accessToken,
  RecentFilesResults,
  FavoriteFilesResults,
  TrashFilesResults,
  SideBarWidth,
  isRecentResults,
  isTrashResults,
  isFavoritesResults,
  isSearchResults,
  sideBarRefreshName,
  setInitialFileLoading,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const popupRef = useRef(null);

  useEffect(() => {
    if (sideBarRefreshName.name === "__Recent__") {
      handleRecentFiles(sideBarRefreshName.event);
    }

    if (sideBarRefreshName.name === "__Favorites__") {
      handleFavorites(sideBarRefreshName.event);
    }

    if (sideBarRefreshName.name === "__Trash__") {
      handleTrash(sideBarRefreshName.event);
    }
  }, [sideBarRefreshName]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const uploadHandeler = () => {
    ContextCloseHandeler();
    onUploadButtonClick();
    togglePopup();
  };

  const handleCreateFolderClick = () => {
    onCreateFolderClick();
    togglePopup();
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupOpen(false);
    }
  };

  const handleRecentFiles = async (e) => {
    setInitialFileLoading(true);

    e.preventDefault();
    //onFolderClick("__Recent__");
    //RecentFilesResults([]);
    try {
      // API GET CALLRecentFilesResults
      dispatch(setSearchTerm(""));
      let _recentFilesResult = await RecentFiles(accessToken);
      if (_recentFilesResult.result == true) {
        console.log(_recentFilesResult);
        const recent = _recentFilesResult.data.map((item) => ({
          ...item,
          path: item.name,
          name: item.name.split("/").pop(),
          location: item.name.split("/").slice(0, -1).pop(),
          isFolder: item.folder,
          lastAccessedTime: item.lastAccessedTime,
        }));

        RecentFilesResults(recent);
        setInitialFileLoading(false);
      } else {
      }
    } catch (err) {
      console.log("Error Geting Recent Files:", err);
    }
  };

  const handleFavorites = async (e) => {
    setInitialFileLoading(true);

    e.preventDefault();
    //onFolderClick("__Favorites__");
    //FavoriteFilesResults([]);
    try {
      // API GET CALLRecentFilesResults
      dispatch(setSearchTerm(""));
      let _favoritesResult = await Favorites(accessToken);
      if (_favoritesResult.result == true) {
        const fav = _favoritesResult.data.map((item) => ({
          ...item,
          path: item.name,
          name: item.name.split("/").pop(),
          location: item.name.split("/").slice(0, -1).pop(),
          isFolder: item.folder,
          lastModifiedTime: item.lastModifiedTime,
        }));

        FavoriteFilesResults(fav);
        setInitialFileLoading(false);
      } else {
      }
    } catch (err) {
      console.log("Error Geting Recent Files:", err);
    }
  };

  const handleTrash = async (e) => {
    setInitialFileLoading(true);

    e.preventDefault();
    //onFolderClick("__Favorites__");
    //FavoriteFilesResults([]);
    try {
      // API GET CALLRecentFilesResults
      dispatch(setSearchTerm(""));
      let _trashResult = await Trash(accessToken);
      if (_trashResult.result == true) {
        const trash = _trashResult.data.map((item) => ({
          ...item,
          path: item.name,
          name: item.name.split("/").pop(),
          location: item.name.split("/").slice(0, -1).pop(),
          isFolder: item.folder,
          deletedTime: item.deletedTime,
        }));
        TrashFilesResults(trash);
        setInitialFileLoading(false);
      } else {
      }
    } catch (err) {
      console.log("Error Geting Recent Files:", err);
    }
  };

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  return (
    <div className="sidebar1">
      <div className="sidebar1-btn">
        {/* <button onClick={uploadHandeler}>
          <CloudUploadOutlinedIcon />
          <span>Upload</span>
        </button> */}
        <button onClick={togglePopup}>
          <CloudUploadOutlinedIcon />
          <span> {t('Upload')}</span>
          
        </button>
      </div>
      {/* <hr className="hr" /> */}

      <div className="sidebar1-options">
        <div
          className={`sidebar1-option ${
            selectedFolder === "__Recent__" ? "selected" : ""
          }`}
          onClick={handleRecentFiles}
        >
         
          <HistoryOutlinedIcon /> 
          <span className="sidebar1-recent"> {t('Recent')}</span>
        </div>

        {/* <div className="sidebar1-option recent-file">
          <FileOpenOutlinedIcon />
          <span>Recent</span>
        </div> */}

        <div
          className={`sidebar1-option ${
            selectedFolder === "__Favorites__" ? "selected" : ""
          }`}
          onClick={handleFavorites}
        >
          <BookmarkBorderOutlinedIcon />
          <span className="sidebar1-favorites">{t('Favorites')}</span>
        </div>

        <div
          className={`sidebar1-option ${
            selectedFolder === "__Trash__" ? "selected" : ""
          }`}
          onClick={handleTrash}
        >
          <DeleteOutlineOutlinedIcon />
          <span className="sidebar1-trash">{t('Trash')}</span>
        </div>
        {/* <div
                className={`sidebar1-option ${
                  selectedFolder === "__Suggestion__" ? "selected" : ""
                }`}
                onClick={handleSuggestions}
              >
                <FeedbackIcon />
                <span className="sidebar1-trash">Suggestion Box</span>
              </div> 
          */}

        {/* <hr style={{ marginBottom: "10px" }} /> */}

        <div
          className={`sidebar1-option ${
            selectedFolder === "Home" ? "selected" : ""
          }`}
          onClick={() => onFolderClick("Home")}
        >
          <HomeOutlinedIcon />
          <span className="sidebar1-home">{t('Home')}</span>
        </div>

        <div className="folder-tree1">
          {Object.keys(folderTree).length > 0 ? (
            Object.keys(folderTree).map((folderName) => (
              <Folder
                key={folderName}
                folderName={folderName}
                folderPath={folderName}
                children={folderTree[folderName]}
                onClickFolder={onFolderClick}
                selectedFolder={selectedFolder}
                sideBarWidth={SideBarWidth}
              />
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>

      {isPopupOpen && (
        // !isRecentResults &&
        // !isTrashResults &&
        // !isFavoritesResults &&
        // !isSearchResults &&
        <div className="popup-menu" ref={popupRef}>
          <ul>
            <li onClick={uploadHandeler}>
              <CloudUploadOutlinedIcon style={{ marginRight: "10px" }} />
              {t('Upload')} {t('File')}
            </li>
            <li onClick={handleCreateFolderClick}>
              <FolderIcon style={{ marginRight: "10px" }} />
              {t('Create')} {t('Folder')}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default SideBar1;

// function getMaxCharactersFromWidth(sidebarWidth, level) {
//   // Map the sidebar width to the number of characters
//   // At 200px width, 5 characters, at 350px width, 10 characters
//   const minWidth = 200;
//   const maxWidth = 350;
//   let minChars = 7;
//   let maxChars = 21;

//   if (level > 4) {
//     minChars = 3;
//   }

//   // Calculate the number of characters proportionally
//   const characters = Math.round(
//     minChars +
//       ((sidebarWidth - minWidth) / (maxWidth - minWidth)) *
//         (maxChars - minChars)
//   );

//   return characters;
// }

// function Folder({
//   folderName,
//   folderPath,
//   children,
//   onClickFolder,
//   selectedFolder,
//   level = 0, // Indentation level
//   autoOpen = false, // New prop to control auto-opening
//   sideBarWidth,
// }) {
//   const [isOpen, setIsOpen] = useState(autoOpen);

//   const toggleFolder = () => {
//     setIsOpen(!isOpen);
//     if (onClickFolder) {
//       onClickFolder(folderPath);
//     }
//   };

//   // Determine if the folder name is long (more than 6 characters)
//   const isLongName = folderName.length > 6;

//   // Truncate the folder name if it's long
//   const displayName = isLongName ? `${folderName.slice(0, 6)}...` : folderName;

//   // Automatically open the folder if it's selected
//   React.useEffect(() => {
//     if (selectedFolder && selectedFolder.startsWith(folderPath)) {
//       setIsOpen(true);
//     }
//   }, [selectedFolder]);

//   // -----------------------------------------------------------

//   // Calculate the maximum number of characters based on sidebar width
//   const maxChars = getMaxCharactersFromWidth(sideBarWidth, level);

//   // Truncate the folder name based on the calculated number of characters
//   const truncatedName =
//     folderName.length > maxChars
//       ? `${folderName.slice(0, maxChars)}...`
//       : folderName;

//   return (
//     <div className="folder">
//       <div
//         className={`sidebar1-option folder-header ${
//           selectedFolder === folderPath ? "selected" : ""
//         }`}
//         onClick={toggleFolder}
//       >
//         <span
//           style={{
//             paddingLeft: `${level * 15}px`,
//             display: "flex",
//             alignItems: "center",
//             gap: "5px",
//           }}
//         >
//           {isOpen ? <ArrowDropDownSharpIcon /> : <ArrowRightSharpIcon />}
//           {isOpen ? <FolderIcon /> : <FolderIcon />}
//           <span className={`folder-name ${isLongName ? "long-name" : ""}`}>
//             {displayName}
//           </span>
//         </span>
//       </div>

//       {isOpen && children && (
//         <div className="folder-children">
//           {Object.keys(children).map((childFolder) => (
//             <Folder
//               key={childFolder}
//               folderName={childFolder}
//               folderPath={`${folderPath}/${childFolder}`}
//               children={children[childFolder]}
//               onClickFolder={onClickFolder}
//               selectedFolder={selectedFolder}
//               level={level + 1}
//             />
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }

// function SideBar1({
//   folderTree,
//   onFolderClick,
//   selectedFolder,
//   onUploadButtonClick,
//   ContextCloseHandeler,
//   onCreateFolderClick,
//   accessToken,
//   RecentFilesResults,
//   FavoriteFilesResults,
//   TrashFilesResults,
//   SideBarWidth,
// }) {
//   const [isPopupOpen, setIsPopupOpen] = useState(false);

//   const popupRef = useRef(null);

//   const togglePopup = () => {
//     setIsPopupOpen(!isPopupOpen);
//   };

//   const uploadHandeler = () => {
//     ContextCloseHandeler();
//     onUploadButtonClick();
//     togglePopup();
//   };

//   const handleCreateFolderClick = () => {
//     onCreateFolderClick();
//     togglePopup();
//   };

//   const handleClickOutside = (event) => {
//     if (popupRef.current && !popupRef.current.contains(event.target)) {
//       setIsPopupOpen(false);
//     }
//   };

//   const handleRecentFiles = async (e) => {
//     e.preventDefault();
//     //onFolderClick("__Recent__");
//     //RecentFilesResults([]);
//     try {
//       // API GET CALLRecentFilesResults
//       let _recentFilesResult = await RecentFiles(accessToken);
//       if (_recentFilesResult.result == true) {
//         console.log(_recentFilesResult);
//         const recent = _recentFilesResult.data.map((item) => ({
//           ...item,
//           path: item.name,
//           name: item.name.split("/").pop(),
//           location: item.name.split("/").slice(0, -1).pop(),
//           isFolder: item.folder,
//         }));
//         RecentFilesResults(recent);
//       } else {
//       }
//     } catch (err) {
//       console.log("Error Geting Recent Files:", err);
//     }
//   };

//   const handleFavorites = async (e) => {
//     e.preventDefault();
//     //onFolderClick("__Favorites__");
//     //FavoriteFilesResults([]);
//     try {
//       // API GET CALLRecentFilesResults
//       let _favoritesResult = await Favorites(accessToken);
//       if (_favoritesResult.result == true) {
//         const fav = _favoritesResult.data.map((item) => ({
//           ...item,
//           path: item.name,
//           name: item.name.split("/").pop(),
//           location: item.name.split("/").slice(0, -1).pop(),
//           isFolder: item.folder,
//         }));
//         FavoriteFilesResults(fav);
//       } else {
//       }
//     } catch (err) {
//       console.log("Error Geting Recent Files:", err);
//     }
//   };

//   const handleTrash = async (e) => {
//     e.preventDefault();
//     //onFolderClick("__Favorites__");
//     //FavoriteFilesResults([]);
//     try {
//       // API GET CALLRecentFilesResults
//       let _trashResult = await Trash(accessToken);
//       if (_trashResult.result == true) {
//         const trash = _trashResult.data.map((item) => ({
//           ...item,
//           path: item.name,
//           name: item.name.split("/").pop(),
//           location: item.name.split("/").slice(0, -1).pop(),
//           isFolder: item.folder,
//         }));
//         TrashFilesResults(trash);
//       } else {
//       }
//     } catch (err) {
//       console.log("Error Geting Recent Files:", err);
//     }
//   };

//   useEffect(() => {
//     if (isPopupOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isPopupOpen]);

//   return (
//     <div className="sidebar1">
//       <div className="sidebar1-btn">
//         {/* <button onClick={uploadHandeler}>
//           <CloudUploadOutlinedIcon />
//           <span>Upload</span>
//         </button> */}
//         <button onClick={togglePopup}>
//           <CloudUploadOutlinedIcon />
//           <span>Upload</span>
//         </button>
//       </div>
//       {/* <hr className="hr" /> */}

//       <div className="sidebar1-options">
//         <div
//           className={`sidebar1-option ${
//             selectedFolder === "__Recent__" ? "selected" : ""
//           }`}
//           onClick={handleRecentFiles}
//         >
//           <HistoryOutlinedIcon />
//           <span className="sidebar1-recent"> Recent</span>
//         </div>

//         {/* <div className="sidebar1-option recent-file">
//           <FileOpenOutlinedIcon />
//           <span>Recent</span>
//         </div> */}

//         <div
//           className={`sidebar1-option ${
//             selectedFolder === "__Favorites__" ? "selected" : ""
//           }`}
//           onClick={handleFavorites}
//         >
//           <BookmarkBorderOutlinedIcon />
//           <span className="sidebar1-favorites"> Favorites</span>
//         </div>

//         <div
//           className={`sidebar1-option ${
//             selectedFolder === "__Trash__" ? "selected" : ""
//           }`}
//           onClick={handleTrash}
//         >
//           <DeleteOutlineOutlinedIcon />
//           <span className="sidebar1-trash">Trash</span>
//         </div>

//         {/* <hr style={{ marginBottom: "10px" }} /> */}

//         <div
//           className={`sidebar1-option ${
//             selectedFolder === "Home" ? "selected" : ""
//           }`}
//           onClick={() => onFolderClick("Home")}
//         >
//           <HomeOutlinedIcon />
//           <span className="sidebar1-home">Home</span>
//         </div>

//         <div className="folder-tree1">
//           {Object.keys(folderTree).length > 0 ? (
//             Object.keys(folderTree).map((folderName) => (
//               <Folder
//                 key={folderName}
//                 folderName={folderName}
//                 folderPath={folderName}
//                 children={folderTree[folderName]}
//                 onClickFolder={onFolderClick}
//                 selectedFolder={selectedFolder}
//                 sideBarWidth={SideBarWidth}
//               />
//             ))
//           ) : (
//             <div></div>
//           )}
//         </div>
//       </div>
//       {isPopupOpen && (
//         <div className="popup-menu" ref={popupRef}>
//           <ul>
//             <li onClick={uploadHandeler}>
//               <CloudUploadOutlinedIcon style={{ marginRight: "10px" }} />
//               Upload File
//             </li>
//             <li onClick={handleCreateFolderClick}>
//               <FolderIcon style={{ marginRight: "10px" }} />
//               Create Folder
//             </li>
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// }

// export default SideBar1;
